import { useNavigate } from 'react-router-dom';
import './footer.css';

export const Footer = () => {
    const navigate = useNavigate();

    return (
        <section onClick={() => {navigate('/reachout')}} className='footer-section w-screen bg-white py-3 fixed bottom-0 z-50'>
            <h3 className='relative  cursor-pointer mix-blend-difference text-[11.87pt] md:text-[30pt] lg:text-[30pt] uppercase font-semibold '>reach out</h3>
        </section>
    )
}