import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import BrandProd1 from "../../assets/new_prods/branding/BrandingSTCover1.png";
import BrandProd1Mobile from "../../assets/new_prods/branding/BrandingSTMCover1.png";
// import BrandProd1 from "../../assets/products/Branding/Branding-Nesod-2.png";
import BrandProd2 from "../../assets/new_prods/branding/BrandingSTITem1.png";
import BrandProd3 from "../../assets/new_prods/branding/BrandingSTItem2.png";

import AirProd1 from "../../assets/new_prods/branding/BrandingNDCover1.png";
import AirProd1Mobile from "../../assets/new_prods/branding/BrandingNDMCover2.png";
import AirProd2 from "../../assets/new_prods/branding/BrandingNDItem1.png";
import AirProd3 from "../../assets/new_prods/branding/BrandingNDItem2.png";

import NarrativeProd1 from '../../assets/new_prods/branding/BrandingTHCover1.png'
import NarrativeProd1Mobile from '../../assets/new_prods/branding/BrandingTHMCover1.png'
import NarrativeProd2 from '../../assets/new_prods/branding/BrandingTHItem1.png'
import NarrativeProd3 from '../../assets/new_prods/branding/BrandingTHItem2.png'

import laislaProd1 from "../../assets/services/3.png"
import laislaProd2 from "../../assets/services/2.png"
import laislaProd3 from "../../assets/services/1.png"

import ultimaProd1 from "../../assets/services/10164.png"
import ultimaProd2 from "../../assets/services/10165.png"
import ultimaProd3 from "../../assets/services/10166.png"
import "./services.css"
import { Box, Rating, Stack } from "@mui/material";
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const brandProsData = [
  {
    tagName: 'NESODDBIENE',
    bannerImg: BrandProd1,
    mobileBanner: BrandProd1Mobile,
    leftImg: BrandProd2,
    rightImg: BrandProd3,
    brief: `Create a distinctive logo and branding for a Norwegian honey producer, Nesoddbiene, founded by beekeepers Georges and Ronni. With a passion for beekeeping since 2018, they sell honey and maintain a small local lot. Committed to quality, we wanted to give them something modern and memorable.`,
    process: 'We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.',
    process1: 'We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.',
    review: `The team brilliantly captured Nesoddbiene's essence, crafting a logo and branding that radiate modernity and memorability. Reflecting our commitment to quality honey, the design resonates with our passion for beekeeping. The distinctive identity perfectly mirrors our story, making Nesoddbiene stand out in the hive of honey producers. Exceptional work!`,
    reviewUser: `Ronni. S`,
    reserved: false,
  },
  {
    tagName: 'Airquip',
    bannerImg: AirProd1,
    mobileBanner: AirProd1Mobile,
    leftImg: AirProd2,
    rightImg: AirProd3,
    brief: `Comprehensive branding for an industrial company specialising in compressed air systems, aiming to break away from their parent company, Fluid Aire Dynamics, and establish a distinct identity for Airquip. Requirements included logo, brand mark design, colour palette, fonts, and various collateral such as letterhead, uniform, and business card designs. They emphasised a preference for simple and effective branding.`,
    process: 'Delving into Airquip’s identity, studying their existing ideas and preferences. Collaborating closely, we translated their vision into a clean and impactful logo and brand mark. The design process involved exploring various concepts and refining them based on client feedback. The final deliverables were compiled into a concise brand guide, presenting a cohesive and effective visual identity for Airquip.',
    process1: '',
    review: `This freelancer was amazing to work with! Great communication and amazing work.`,
    reviewUser: `Bella S.`,
    reserved: true,
  },
  {
    tagName: 'Narrative Talent',
    bannerImg: NarrativeProd1,
    mobileBanner: NarrativeProd1Mobile,
    leftImg: NarrativeProd2,
    rightImg: NarrativeProd3,
    brief: `Amanda approached us for a logo and presentation for Narrative Talent, her PR & Communications talent acquisition company. As advocates for the industry, Amanda’s approach prioritises a holistic talent acquisition suite, promising to champion brands and curate services that attract, engage, hire, and retain the best PR & Communications talent in APAC.`,
    process: 'We began by understanding Amanda’s business ethos and career journey. Crafting a logo that reflected the brand’s commitment to holistic talent solutions, we then developed a presentation capturing Amanda’s unique approach. Focusing on her career insights and the ability to connect businesses with top talent, the final deliverables showcased Narrative Talent’s expertise in the APAC region and Amanda’s business-focused talent acquisition strategies.',
    process1: '',
    review: `Working with Joe on Narrative Talent’s brand guidelines was effortless. With his attention to detail and creative design, he crafted a cohesive and visually stunning identity that perfectly captured our brand’s essence.`,
    reviewUser: `Amanda. V`,
    reserved: false,
  },
]

export const Branding = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])

  return (
    <div className="service-pages bg-[#0e0e0e] pt-2 md:pt-4 lg:pt-4 pb-14 md:pb-32 lg:pb-32">
      <Header />
      {brandProsData.map((brandData, key) => (
        <ServiceSection 
        key={key}
        pageIndex={key}
        isScrollAnimation={key !== 0} reserved={brandData.reserved}
        mobileBanner={brandData.mobileBanner}
        bannerImg={brandData.bannerImg} prod1={brandData.leftImg} prod2={brandData.rightImg}
        tagName={brandData.tagName} brief={brandData.brief} process={brandData.process} 
        process1={brandData.process1} review={brandData.review} reviewuser={brandData.reviewUser}
        />
      ))}
      
      <Footer />
    </div>
  )
}