import { useEffect, useRef } from 'react';
import bgVideo from '../assets/bg.mp4';
import vposter from "../assets/vposter.png";
import BackgroundVideo from 'react-background-video-player';
import "./home.css";
import { Header } from '../Components/Header';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';

import fanSymbol from '../assets/symbols/fan.png';
import triangleSymbol from '../assets/symbols/triangle.png';
import polygonSymbol from '../assets/symbols/polygon.png';

// import BrandProd1 from "../assets/products/Branding/Branding-Nesod-2.png";
// import BrandProd2 from "../assets/products/Branding/Airquip-1.png";
// import BrandProd3 from "../assets/products/Branding/Narrative-Talent-1.png";

import BrandProd1 from "../assets/new_prods/branding/BrandingSTMCover1.png";
import BrandProd2 from "../assets/new_prods/branding/BrandingNDMCover2.png";
import BrandProd3 from "../assets/new_prods/branding/BrandingTHMCover1.png";

import PackageProd1 from "../assets/products/Packaging/HempMe-2.png";
import PackageProd2 from "../assets/products/Packaging/Packaging-La-Isla-1.png";
import PackageProd3 from "../assets/products/Packaging/Packaging-Ultima-1.png";

import PresentProd1 from "../assets/products/Presentations/Hawkeye-1.png";
import PresentProd2 from "../assets/products/Presentations/Presentation-Dogma-1.png";
import PresentProd3 from "../assets/products/Presentations/Presentation-Forge-2.png";

import VisualProd1 from "../assets/products/Visual-Media/Searchlight-3.png";
import VisualProd2 from "../assets/products/Visual-Media/Visual-Media-Mellowtone-1.png";
import VisualProd3 from "../assets/products/Visual-Media/Assorted-Visual-Media-3.png";

import WebProd1 from "../assets/products/Web/Web-Totus-1.png";
import WebProd2 from "../assets/products/Web/Web-Bailuna-1.png";
import WebProd3 from "../assets/products/Web/Vayo-1.png";

import IllustrationProd1 from "../assets/products/Illustrations/Soul-Masters-1.png"
import IllustrationProd2 from "../assets/products/Illustrations/Illustration-Animal-Gang-1.png"
import IllustrationProd3 from "../assets/products/Illustrations/Assorted-Illustrations-1.png"

import ThreeProd1 from "../assets/products/3D/3D-Homestead2100-1.png";
import ThreeProd2 from "../assets/products/3D/3D-Mooch-1.png";
import ThreeProd3 from "../assets/products/3D/Vera-2.png";

import prod2 from "../assets/products/2.png"
import prod3 from "../assets/products/10135.png"
import prod4 from "../assets/products/Group-3828.png"
import prod5 from "../assets/products/Group-3502.png"
import prod6 from "../assets/products/Group-3454.png"
import prod7 from "../assets/products/SM-1.png"
import prod8 from "../assets/products/Artboard.png"
import { LandingRightSection } from '../Components/Landing/LandingRightSection';
import { LandingLeftSection } from '../Components/Landing/LandingLeftSection';

import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import {isMobile} from 'react-device-detect';
import { Footer } from '../Components/Footer';
import { SplitText } from 'gsap/all';

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother, SplitText);
function calculateProgress(progress) {
  let progressVal = 0;
  const progressScaled = progress * 10
  if(progressScaled > 2 && progressScaled < 8){
    progressVal = ((progressScaled - 1) / 6);
  }else if(progressScaled < 2){
    progressVal = 0;
  }else if(progressScaled > 8){
    progressVal = 1;
  }
  return progressVal;
}
export const Home = () => {
  const main = useRef();
  const videoRef = useRef();
  const smoother = useRef();

  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log(videoRef)
    setTimeout(() => {
      if(videoRef.current){
        videoRef.current.play()
      }
    }, 1000)
    
   }, [])
  const videoLoaded = () => {
    if(videoRef.current){
      videoRef.current.play()
    }
  }
  useGSAP(
    () => {
      // if(videoRef.current){
      //     videoRef.current.play()
      //   }
      if(!isMobile){
        let pathVal = 200;
        smoother.current = ScrollSmoother.create({
          smooth: 1,
          speed: 1,
          effects: true,
        });
        let observeEles = gsap.utils.toArray(".observerSec");
        function gotoPanel(panelElements, progress) {
          console.log(panelElements, progress);
          // const realProgress = calculateProgress(progress);
          // console.log(progress, realProgress);
          // panelElements.map((targetItem, key) => {
          //   const calCip = (progress * 100 * (panelElements.length - 1)) -(key * 100);
          //   // const calCip =  (progress * 200) - ((panelElements.length - key - 1) * 100);
          //   // const bgPos = (progress * 100  * (panelElements.length - 1)) - (key * 100) - 10;
          //   const bgPos = (key * 100) - (progress * 100 * (panelElements.length - 1));
          //   gsap.to(targetItem, {
          //     clipPath: `inset(0px 0px ${calCip}%)`,
          //     ease: 'none',
          //     onComplete: () => {
          //     }
          //   })

          //   const baseImage = targetItem.querySelector(".baseImage")
          //       gsap.to(baseImage, {
          //         backgroundPositionY: `${(bgPos)}px`,
          //         ease: 'none',
          //         onComplete: () => {
          //         }
          //       })
            
          // })
        }

       

        observeEles.forEach((EleItem, key) => {
          let swipePanels = gsap.utils.toArray(EleItem.querySelectorAll(".prod2"));
          console.log(swipePanels);
          gsap.defaults({ease: "none", duration: 3});
          const tlProcess = gsap.timeline();
          tlProcess.fromTo(swipePanels[1], {opacity: 1}, {opacity: 1}, "+=1");
          tlProcess.fromTo(swipePanels[0], {clipPath: `inset(0px 0px ${0}%)`}, {clipPath: `inset(0px 0px ${100}%)`}, "+=0.5");
          tlProcess.fromTo(swipePanels[1], {opacity: 1}, {opacity: 1}, "+=1");
          tlProcess.fromTo(swipePanels[1], {clipPath: `inset(0px 0px ${0}%)`}, {clipPath: `inset(0px 0px ${100}%)`}, "+=0.5");
          tlProcess.fromTo(swipePanels[2], {opacity: 1}, {opacity: 1}, "+=1");
          // tlProcess.from(swipePanels[], {clipPath: `inset(0px 0px ${100}%)`}, "+=2");
          // tl.from(".pinned-text", {scaleY:0,transformOrigin:("bottom center")},"+=2")
          // tl.to(".pinned-text p", {opacity:1})
          // tl.from(".image-wipe--two", {yPercent: 100},"+=2")
          // tl.to(".pinned-text p", {opacity:0})
          // tl.to(".pinned-text", {scaleY:0,transformOrigin:("bottom center")},"+=2")
          ScrollTrigger.create({
            animation: tlProcess,
            trigger: EleItem,
            pin: true,
            anticipatePin: 1,
            start: "center center",
            end: "+=200%",
            // markers: true,
            fastScrollEnd: false,
            scrub: 1,
            onEnter: (self) => {
              
            },
            onEnterBack: (self) => {
              // self.scroll(self.start);
              // preventScroll.enable();
              // intentObserver.enable();
            },
            // onUpdate: (self) =>{ gotoPanel(swipePanels, self.progress)},
            onLeave: (self) => {
              console.log('onLeave', self);
              // self.enable();
              // self.isActive=false;
            },
            onLeaveBack: (self) => {
              console.log('Leave Back', self);
              // self.isActive=false;
              // self.enable();
            }
          })
        });
      }
      // document.getElementById('landing-video').play();
    },
    { scope: main }
  );

  const swiperChange = (e) => {
    console.log(e.activeIndex, e.slides, e);
    const acitveSlider = e.slides[e.activeIndex];
    console.log(acitveSlider);
    const textData = acitveSlider.querySelector(".banner-h4");
    console.log(textData);
    if(textData){
      gsap.to(textData, {
        opacity: 1
      })
      const childSplit = new SplitText(textData, {
        type: "lines",
        linesClass: "split-child"
      });
      const parentSplit = new SplitText(textData, {
        // type: "lines",
        linesClass: "split-parent"
      });
      console.log(childSplit);
      gsap.from(childSplit.lines, {
        duration: 0.7,
        yPercent: 100,
        autoAlpha: 0,
        ease: "none",
        stagger: 0.5,
        delay: 0.5,
        onComplete: () => {
          const subTextData = acitveSlider.querySelector('.banner-h4-subtitle');
          gsap.to(subTextData, {opacity: 1});
          const subChildSplit = new SplitText(subTextData, {
            type: "lines",
            linesClass: "split-child"
          });
          const subParentSplit = new SplitText(subTextData, {
            // type: "lines",
            linesClass: "split-parent"
          });
          gsap.from(subChildSplit.lines, {
            duration: 0.5,
            yPercent: -100,
            autoAlpha: 0,
            ease: "none",
            stagger: 0,
          })
        }
      })
    }
    const headerLogo = acitveSlider.querySelector(".banner-h2");
    if(headerLogo){
      // gsap.to(headerLogo, {
      //   opacity: 1
      // })
      let split = new SplitText(headerLogo, {
        type: "lines,words,chars"
      });
      
      gsap.set(split.chars, { perspective: 500 });
      let tween = gsap.from(split.chars, {
        duration: 0.4,
        yPercent: 20,
        autoAlpha: 0,
        ease: "none",
        stagger: 0.1,
        delay: 0.3,
        onComplete: () => {
          const subtext = acitveSlider.querySelector('.banner-subtitle');
            gsap.to(subtext, {opacity: 1});
            let subSplit = new SplitText(subtext, {
              type: "lines",
              linesClass: "split-child"
            });
            console.log(subtext, subSplit);
            gsap.from(subSplit.lines, {
              duration: 1,
              yPercent: 100,
              ease: "power3.inOut",
              stagger: 0.1,
            })
        }
      })
    }
    let prevSlider = e.slides[e.previousIndex]
    const preheaderLogo = prevSlider.querySelector(".banner-h2");
    if(preheaderLogo){
      // gsap.to(preheaderLogo, {opacity:0})
    }
    
    const pretextData = prevSlider.querySelector(".banner-h4");
    if(pretextData){
      gsap.to(pretextData, {opacity:0})
    }
    const presubtext = prevSlider.querySelector('.banner-subtitle');
    if(presubtext){
      gsap.to(presubtext, {opacity: 0})
    }
    const presubTextData = prevSlider.querySelector('.banner-h4-subtitle');
    if(presubTextData){
      gsap.to(presubTextData, {opacity: 0});
    }
      
  }
  
  const inistSwiper = (e) => {
    const acitveSlider = e.slides[e.activeIndex];
    
    const headerLogo = acitveSlider.querySelector(".banner-h2");
    console.log('ddddd',e.activeIndex, acitveSlider, headerLogo, e);
      if(headerLogo){
        gsap.to(headerLogo, {
          opacity: 1
        })
        let split = new SplitText(headerLogo, {
          type: "lines,words,chars"
        });
        
        gsap.set(split.chars, { perspective: 500 });
        let tween = gsap.from(split.chars, {
          duration: 1,
          yPercent: 100,
          autoAlpha: 0,
          ease: "none",
          stagger: 1,
          onComplete:() => {
            const subtext = acitveSlider.querySelector('.banner-subtitle');
            gsap.to(subtext, {opacity: 1});
            let subSplit = new SplitText(subtext, {
              type: "lines",
              linesClass: "split-child"
            });
            console.log(subtext, subSplit);
            gsap.from(subSplit.lines, {
              duration: 0.2,
              xPercent: 100,
              ease: "none",
              stagger: 0.3,
            })
          }
        });
      }
  }
  return (
    <div className="landing-page bg-[#0e0e0e]">
     
      <Header />
      <div ref={main} id='smooth-wrapper'>
        <div id='smooth-content'>
          
          {/* <section className={isMobile? "pageSection videobg":"pageSection"}> */}
          <section className="pageSection">
            {/* <BackgroundVideo 
              src={bgVideo}
              loop={true}
              autoPlay={true}
            /> */}
            <video ref={videoRef} onLoadedData={() => videoLoaded()} className='landing-video' id="landing-video" poster={vposter} autoPlay playsInline loop muted >
              <source src={bgVideo} type="video/mp4"></source>
            </video>
            <div className='absolute w-full h-full top-0 left-0'>
            <Swiper
            spaceBetween={500}
              slidesPerView={1}
              loop={true}
              controller={false}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              onSlideChange={(e) => {swiperChange(e)}}
              // onAfterInit={(swiper) => inistSwiper(swiper)}
              modules={[Autoplay]}
              className='h-screen w-full'
            >
              <SwiperSlide >
                <div className='banner-bar'>
                  <Box className="hidden md:block">
                      <h2 onClick={() => videoLoaded()} className='banner-h2 font-extrabold overflow-hidden leading-[245px]'>
                        laconic.
                      </h2>
                      <h5 className='banner-subtitle overflow-hidden text-[34px] '><b>adj.</b> expressing much with few words</h5>
                    </Box>
                    <Box className="block md:hidden text-right">
                      <h4 id='exploreH' className=' text-[35pt] uppercase leading-[40pt] text-black font-extrabold text-center'>
                        <p className=''>EXPLORE</p> <p className=''>DESIGN</p> <p className=''>CREATE</p>  
                      </h4>
                      <h4 className='text-[10pt] font-extralight text-center text-black animate-slideinright mt-5'>
                        <p className='animate-slideinright animation-delay-75'>Our team of multidisciplinary</p>
                        <p className='animate-slideinright animation-delay-300'>designers, creatives and artists</p>
                        <p className='animate-slideinright animation-delay-500'>will breathe life into your idea</p>
                      </h4>
                    </Box>
                </div>         
              </SwiperSlide>
              <SwiperSlide >
                <div className='banner-bar'>
                    
                      <Box className="hidden md:flex">
                        <Grid item xs={4} borderRight={1} textAlign={'right'} paddingRight={2}>
                            <h4 className='banner-h4  overflow-hidden opacity-0'>
                              EXPLORE <br /> DESIGN <br /> CREATE
                            </h4>
                          </Grid>
                          <Grid item xs={8} textAlign={'left'} paddingLeft={2}>
                            <h4 className='banner-h4-subtitle opacity-0 overflow-hidden'>
                              Our team of multidisciplinary<br />
                              designers, creatives and artists<br />
                              will breathe life into your idea
                                
                            </h4>
                          </Grid>
                      </Box>
                      <Box className="block md:hidden text-right">
                        <h4 className='text-[35pt] uppercase leading-[40pt] text-black font-extrabold text-center'>
                          <p className='animate-slideinbottom animation-delay-500'>EXPLORE</p> <p className='animate-slideinbottom  animation-delay-300'>DESIGN</p> <p className='animate-slideinbottom animation-delay-100'>CREATE</p>  
                        </h4>
                        <h4 className='text-[10pt] font-extralight text-center text-black animate-slideinright mt-5'>
                          <p className='animate-slideinright animation-delay-75'>Our team of multidisciplinary</p>
                          <p className='animate-slideinright animation-delay-300'>designers, creatives and artists</p>
                          <p className='animate-slideinright animation-delay-500'>will breathe life into your idea</p>
                        </h4>
                      </Box>
                  </div>
              </SwiperSlide>
            </Swiper>
            </div>
            
          </section>
          <section className='landing-section bg-[#0e0e0e] flex justify-center items-center py-12 px-2'>
            <LandingLeftSection 
              prodImg={BrandProd1}
              prod2Img={BrandProd2} 
              prod3Img={BrandProd3}
              title={'Branding'}
              href="/branding"
              subtitle={'The front of your business'}
              subDetail={'from vision, to vector'}
              detail={'We create distinctive brand identities that reflect your business’s core values and connect with your target audience. Our branding services include logo design, typography, color schemes, and comprehensive style guides to ensure consistency across all platforms. Whether building a new brand or refreshing an existing one, we craft identities that resonate, foster trust, and make your brand memorable. Our strategic approach focuses on building recognition and loyalty through cohesive visual storytelling, ensuring your brand stands out in a competitive market and leaves a lasting impression on customers.'}
            />
          </section>
          <section className='landing-section bg-[#0e0e0e] flex justify-center items-center py-12 px-2'>
            <LandingRightSection 
              prodImg={PackageProd1}
              prod2Img={PackageProd2} 
              prod3Img={PackageProd3}
              title={'packaging'}
              href="/packaging"
              subtitle={'Elevate your product'}
              subDetail={'With expertly crafted designs'}
              detail={'We design eye-catching, functional packaging that enhances your product’s appeal while maintaining practical considerations. Our packaging solutions are tailored to your brand’s identity, ensuring that your product stands out on the shelf and attracts customers. Whether for retail, food, or luxury products, we balance aesthetic beauty with usability, making sure your packaging is not only visually compelling but also sustainable and cost-effective. We focus on delivering designs that communicate your product’s value, making a memorable first impression and enhancing the overall customer experience from the moment they see your product.'}
            />
          </section>
          <section className='landing-section bg-[#0e0e0e] flex justify-center items-center py-12 px-2'>
            <LandingLeftSection 
              prodImg={PresentProd1}
              prod2Img={PresentProd2} 
              prod3Img={PresentProd3}
              title={'Presentations'}
              href="/presentations"
              subtitle={'Transform your ideas'}
              subDetail={'into visually stunning presentations'}
              detail={'We design professional, visually impactful presentations that make your message clear and compelling. Whether for corporate meetings, sales pitches, or conferences, our custom-designed presentations are tailored to your brand’s style and goals. We focus on visual storytelling, combining engaging graphics, clean layouts, and clear messaging to create presentations that captivate and inform your audience. Our services ensure your presentations are not only informative but also visually striking, helping you communicate effectively, stand out, and leave a lasting impression on stakeholders or clients.'}
            />
          </section>
          <section className='landing-section bg-[#0e0e0e] flex justify-center items-center py-12 px-2'>
            <LandingRightSection 
              prodImg={VisualProd1}
              prod2Img={VisualProd2} 
              prod3Img={VisualProd3}
              title={'Visual Media'}
              href="/visual-media"
              subtitle={'Ignite your content'}
              subDetail={'with engaging and captivating visuals'}
              detail={'Our visual media services create engaging and high-quality content, from videos to motion graphics, that communicates your brand’s message effectively. We handle the entire production process, from concept development to post-production, ensuring your content aligns with your brand and goals. Whether it’s promotional videos, product demos, or brand storytelling, our visual media services help you engage your audience across digital platforms. We focus on delivering compelling narratives through visual storytelling, boosting your brand’s visibility, and helping you build deeper connections with your target audience.'}
            />
          </section>
          <section className='landing-section bg-[#0e0e0e] flex justify-center items-center py-12 px-2'>
            <LandingLeftSection 
              prodImg={WebProd1}
              prod2Img={WebProd2} 
              prod3Img={WebProd3}
              title={'web'}
              href="/web"
              subtitle={'Unleash the power of your brand'}
              subDetail={'with strategic UI & UX'}
              detail={'We design and develop custom websites that are visually stunning, responsive, and user-friendly. Our web solutions are tailored to your business needs, whether it’s an e-commerce platform, corporate site, or blog. We focus on user experience (UX), intuitive navigation, and seamless functionality to ensure maximum engagement and conversions. Our websites are optimized for SEO and mobile compatibility, delivering a consistent and attractive user experience across all devices. From concept to launch, we handle everything, including ongoing support and updates, ensuring your site remains secure, up-to-date, and aligned with your business goals.'}
            />
          </section>
          <section className='landing-section bg-[#0e0e0e] flex justify-center items-center py-12 px-2'>
            <LandingRightSection 
              prodImg={IllustrationProd1}
              prod2Img={IllustrationProd2} 
              prod3Img={IllustrationProd3}
              title={'Illustration'}
              href="/illustration"
              subtitle={'Bring your ideas to life'}
              subDetail={'with artistic vision'}
              detail={'Our illustration services add a creative and unique flair to your brand’s visual communication. Whether for marketing, editorial, or product design, we craft custom illustrations tailored to your brand’s voice and aesthetic. From simple, minimalist designs to detailed, complex visuals, our illustrations are crafted to captivate and enhance your message. They help explain complex ideas, tell stories, or simply add an artistic touch that sets your brand apart. Each illustration is created with your vision and goals in mind, ensuring it resonates with your audience and enhances your overall brand identity.'}
            />
          </section>
          <section className='landing-section bg-[#0e0e0e]  flex justify-center items-center px-2 pt-12 pb-32 md:pb-4 lg:pb-4'>
            <LandingLeftSection 
              prodImg={ThreeProd1}
              prod2Img={ThreeProd2} 
              prod3Img={ThreeProd3}
              title={'3d'}
              href="/threed"
              subtitle={'Another dimension of creativity'}
              subDetail={'with immersive 3D designs'}
              detail={'Our 3D design services bring concepts, products, and environments to life with exceptional realism. We specialize in 3D modeling, rendering, and animation, delivering detailed visuals for industries like real estate, product design, marketing, and entertainment. Whether you need product prototypes, architectural models, or engaging animations, our expertise ensures precision and creativity. We transform ideas into dynamic, interactive experiences that captivate and communicate effectively. Our 3D designs not only enhance visual appeal but also provide immersive experiences, helping your brand stand out and making complex ideas easier to grasp.'}
            />
          </section>
          
        </div>
      </div>
      <Footer />
    </div>
  )
}