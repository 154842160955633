export const reviewlist = [
    {
      detail: `laconic. were fantastic and helped me with the layout of a trip report with pictures I prepared. Their work made me really happy. I would highly recommend hiring them if you need help with layout and design-related projects.`,
      name: `Giacomo G.`
    },
    {
      detail: `laconic. did an exceptional job retouching and fixing my photos. Their attention to detail is impeccable, and the results are beyond my expectations. Highly recommended for any photo enhancement needs!`,
      name: `Micha F.`
    },
    {
      detail: `laconic. are incredibly thorough and get on with the job. No fuss. They deliver quality work with a high level of professionalism. Will definitely like to work with Iaconic. again.`,
      name: `Candice W.`
    },
    {
      detail: `Iaconic. was very prompt with our changes and deliver what we asked each time. We were able to get to a design we were pleased with. It was within our budget and easily met our timeframe.`,
      name: `Bob B.`
    },
    {
      detail: `I was very pleased with the response and availability throughout the project. Especially as some external factors meant our timelines changed. laconic. responded quickly and professionally to these challenges.`,
      name: `Stephen G.`
    },
    {
      detail: `Nailed it. Outstanding result, worth the investment. I never thought I would go in this direction, but his approach was awesome!`,
      name: `Maksims z.`
    },
    {
      detail: `I've hired laconic. for two projects. I'm more than satisfied with the service, communication, and deliverables. I know I can count on laconic. for support.`,
      name: `Philecia H.`
    },
    {
      detail: `My first time using Freelancer - and laconic - really quite impressed. Found it very straightforward to discuss my image touch-up requirements with him and clear from the finished product he absolutely got what I was after. laconic. will now be my 'go to' for image editing for my commercial website. Five well-earned stars on all fronts.`,
      name: `Kenneth R.`
    },
    {
      detail: `laconic. is very professional and generated exactly what I needed from scratch. Can't recommend them enough - if you need any Photoshop work, I wouldn't go anywhere except laconic.`,
      name: `Richard P.`
    }
  ]