import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import HawkeyeProd1 from "../../assets/products/Presentations/Hawkeye-1.png";
import HawkeyeProd2 from "../../assets/products/Presentations/Hawkeye-2.png";
import HawkeyeProd3 from "../../assets/products/Presentations/Hawkeye-3.png";

import DogmaProd1 from "../../assets/products/Presentations/Presentation-Dogma-1.png";
import DogmaProd2 from "../../assets/products/Presentations/Presentation-Dogma-2.png";
import DogmaProd3 from "../../assets/products/Presentations/Presentation-Dogma-3.png";

import ForgeProd1 from "../../assets/products/Presentations/Presentation-Forge-1.png"
import ForgeProd2 from "../../assets/products/Presentations/Presentation-Forge-2.png"
import ForgeProd3 from "../../assets/products/Presentations/Presentation-Forge-3.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const presentationData = [
  {
    tagName: 'Hawkeye',
    bannerImg: HawkeyeProd1,
    mobileBanner: HawkeyeProd1,
    leftImg: HawkeyeProd2,
    rightImg: HawkeyeProd3,
    brief: `We were tasked with developing a pitch deck for Hawkeye, a device designed to provide cyclists with a distraction-free view of what’s behind them. The AI-driven rear camera detects potential threats and issues timely alerts for enhanced safety awareness.`,
    process: `To fulfil the client’s vision, we began by researching and understanding Hawkeye’s features. We crafted a visually compelling pitch deck, emphasising the product’s unique selling points. Integrating client feedback, we refined the content and design for a polished presentation that effectively communicated Hawkeye’s innovative capabilities.`,
    process1: '',
    review: `laconic. did excellent work!!`,
    reviewUser: `Peter C.`,
    reserved: false,
  },
  {
    tagName: 'Dogma Media',
    bannerImg: DogmaProd1,
    mobileBanner: DogmaProd1,
    leftImg: DogmaProd2,
    rightImg: DogmaProd3,
    brief: `Develop a compelling pitch deck for a brand content & innovation studio. The studio’s vision involved crafting comprehensive content ecosystems to connect brands and audiences through engaging, meaningful content. They curate world-class teams dedicated to delivering optimal results for brands.`,
    process: 'We commenced by thoroughly understanding the studio’s mission and values. Collaborating closely with the client, we translated their vision into a visually appealing pitch deck. Our process involved showcasing the studio’s multidisciplinary approach, highlighting the curated pool of creators, and emphasising the unique value proposition for brands. Through iterative feedback, we refined the pitch deck to effectively communicate the studio’s commitment to delivering exceptional results.',
    process1: '',
    review: `Your creativity blew me away, and I can’t thank you enough for turning our ideas into a visual masterpiece. The way you seamlessly integrated our brand vibe into every nook and cranny of the plan was pure genius – it’s like you read our minds!\nBut what really stood out for me was your keen understanding of the impact visual language can have beyond the numbers. You didn’t just design a document; you crafted an experience. The extra mile you went in making sure our story isn’t just told but felt is something I truly appreciate.`,
    reviewUser: `Dave. A`,
    reserved: true,
  },
  {
    tagName: 'Forge Media',
    bannerImg: ForgeProd1,
    mobileBanner: ForgeProd1,
    leftImg: ForgeProd2,
    rightImg: ForgeProd3,
    brief: `Design a pitch deck for Forge Media, a creative production agency. The client aimed to convey their commitment to impactful partnerships, allowing clients to breathe and witness a dramatic impact on their business. The brief was a blend of creativity and strategy, inviting collaboration around the table for transformative outcomes.`,
    process: `We began by immersing ourselves in Forge Media’s ethos, understanding their commitment to creativity and strategy. Collaborating closely, we crafted a pitch deck that visually expressed their core values. Iterative discussions refined the narrative, ensuring the deck resonated with the client’s desire for impactful partnerships and the ability to achieve great things collaboratively.`,
    process1: '',
    review: `I needed a pitch deck for a business venture I was launching with senior digital executives here in Hollywood for the creator of Netflix smash Global hit “Ozark“. Joe went above and beyond taking notes, redrafting our creative vision (over and over again without a complaint) and always adding a flourish or two that we had not thought of, which truly sold our vision to investors and wildly impressed all of us. His technical skill set is second to none and his artistic vision is unparalleled. Plus not to be discounted, his ability to hit deadlines and deliver on time only furthered his level of professionalism. He will always be my “go-to” creative for all visual materials moving forward!`,
    reviewUser: `Forge Media`,
    reserved: false,
  },
]

export const Presentation = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])


  return (
    <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
      <Header />
      {presentationData.map((presentData, key) => (
        <ServiceSection 
        key={key}
        pageIndex={key}
        isScrollAnimation={key !== 0} reserved={presentData.reserved}
        mobileBanner={presentData.mobileBanner}
        bannerImg={presentData.bannerImg} prod1={presentData.leftImg} prod2={presentData.rightImg}
        tagName={presentData.tagName} brief={presentData.brief} process={presentData.process} 
        process1={presentData.process1} review={presentData.review} reviewuser={presentData.reviewUser}
        />
      ))}
      <Footer />
    </div>
  )
}