import { Box, Divider, Stack, Typography } from "@mui/material"
import './header.css'
import logoImg from '../assets/laconic.png'
import brandingIco from '../assets/icons/BRANDING.gif';
import brandingStick from '../assets/icons/BRANDING-stick.png';
import packagingIco from '../assets/icons/PACKAGING.gif';
import packaginStick from '../assets/icons/PACKAGING-stick.png';
import presentationsIco from '../assets/icons/PRESENTATIONS.gif';
import presentationsStick from "../assets/icons/PRESENTATIONS-stick.png";
import illustrationIco from '../assets/icons/ILLUSTRATION.gif';
import illustrationStick from "../assets/icons/Illustration-stick.png";
import visualmediaIco from '../assets/icons/VISUALMEDIA.gif';
import visualmediaStick from "../assets/icons/Visual-Media-stick.png";
import webIco from '../assets/icons/WEB.gif';
import webStick from "../assets/icons/WEB-stick.png";
import threedIco from '../assets/icons/3D.gif';
import threedStick from "../assets/icons/3D-stick.png";
import socialmediaIco from '../assets/icons/SOCIALMEDIA.gif';
import { HeaderBtn } from "./HeaderBtn";
import bgVideo from '../assets/bg.webm'
import { MobileNav } from "./MobileNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { BrandingIcon, IllustrationIcon, PackagingIcon, PresentationsIcon, ThreedIcon, VisualMediaIcon, WebIcon } from "./icons/HeaderIcons";

import BrandingIcon from "../assets/lottiJson/json/BRANDING.json"
import IllustrationIcon from "../assets/lottiJson/json/Illustration.json"
import PackagingIcon from "../assets/lottiJson/json/PACAGING.json"
import PresentationsIcon from "../assets/lottiJson/json/PRESRNTATIONS.json"
import ThreedIcon from "../assets/lottiJson/json/3D.json"
import PrintMediaIco from "../assets/lottiJson/json/Print_Media.json"
import WebIcon from "../assets/lottiJson/json/web.json"
import { useState } from "react";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHover, setIsHover] = useState('');
  const onHover = (btnName) => {
    setIsHover(btnName)
    // setGifIco(hoverIco);
  }
  const onLeave = () => {
    setIsHover('')
    // setGifIco('');
  }
  return (
    <Box
     className="header w-screen flex bg-[#0f0f0f] justify-between">
      <Stack className="w-full py-2 px-5 md:py-6 md:px-20" direction={'row'} alignItems={"center"} justifyContent={"space-between"}>
        <Box className=" pr-5 bg-[#0f0f0f] w-fit md:w-fit flex justify-between items-center">
          <Link to={'/'}>
          <Box className="header-logo origin-left">
            <h2 className="logoTitle text-[35.4pt] md:text-[53.4pt] lg:text-[53.4pt]">laconic.</h2>
            <p className="text-[5pt] pl-1 md:text-[7.5pt] lg:text-[7.5pt] text-white -mt-4 md:-mt-2 lg:-mt-2"> <b>adj.</b> <span className="italic">Expressing much with few words</span></p>
            {/* <Typography className="logoTitle text-[4.45rem]" variant="h2" fontWeight={900}>laconic.</Typography> */}
            {/* <Typography variant="caption" color="white">
            <b>adj.</b> <span className="italic">Expressing much with few words</span>
            </Typography> */}
          </Box>
          </Link>
        </Box>
        <Box className="block md:hidden">
          <MobileNav />
        </Box>
        <Box className="hidden md:flex justify-around w-full pl-5 bg-[#0f0f0f] ">
            <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('branding'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}
            >
              <HeaderBtn
                iconPath={BrandingIcon} 
                timeOut={3000}
                BtnName={'Branding'}
                href="/branding"
              />
            </Box>
            <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('packaging'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}>
              <HeaderBtn
                iconPath={PackagingIcon} 
                timeOut={3000}
                BtnName={'packaging'}
                href="/packaging"
              />
            </Box>
           <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('presentations'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}>
              <HeaderBtn
                iconPath={PresentationsIcon} 
                timeOut={4600}
                BtnName={'presentations'}
                href="/presentations"
              />
            </Box>
            <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('visual-media'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}>
              <HeaderBtn
                iconPath={PrintMediaIco} 
                timeOut={3040}
                BtnName={'print media'}
                href="/print-media"
              />
            </Box>

            <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('web'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}>
              <HeaderBtn
                iconPath={WebIcon} 
                timeOut={5280}
                BtnName={'Web'}
                href="/web"
              />
            </Box>
            
            <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('illustration'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}>
              <HeaderBtn
                iconPath={IllustrationIcon} 
                timeOut={2520}
                BtnName={'illustration'}
                href="/illustration"
              />
            </Box>
            
            <Box className="w-fit h-full m-auto flex justify-center items-center"
             onMouseEnter={() => {onHover('threed'); console.log('mouseEndtered')}}
             onMouseLeave={() => onLeave()}>
              <HeaderBtn
                iconPath={ThreedIcon} 
                timeOut={5000}
                BtnName={'3d'}
                href="/threed"
              />
            </Box>
            
            <Box className="justify-center items-center">
              <Box className="relative rounded-full h-[111px] w-[111px] flex flex-col justify-between ">
                <Box onClick={() => {navigate('/aboutus')}} className={location.pathname == '/aboutus'? "aboutus-btn active-btn  h-[55px] w-[111px] rounded-t-full border-1 border-b-transparent flex justify-center items-center":"aboutus-btn h-[55px] w-[111px] rounded-t-full border-1 border-b-transparent flex justify-center items-center"}>
                  <Typography className=" cursor-pointer" sx={{mixBlendMode: 'difference'}} textTransform={"uppercase"} variant="caption" fontWeight={600}>
                    about us
                  </Typography>
                </Box>
                <Divider color="transparent" />
                <Box onClick={() => {navigate('/faq')}} className={location.pathname == '/faq'?  "faq-btn active-btn h-[55px] w-[111px] rounded-b-full border-1 border-t-transparent flex justify-center items-center" : "faq-btn h-[55px] w-[111px] rounded-b-full border-1 border-t-transparent flex justify-center items-center"}>
                  <Typography className=" cursor-pointer" sx={{mixBlendMode: 'difference'}}  textTransform={"uppercase"} variant="caption" fontWeight={600}>faq</Typography>
                </Box>
              </Box>
            </Box>
          
        </Box>
      </Stack>
    </Box>
  )
}