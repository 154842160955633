import './App.css';
import { Router } from './router';
import { CssBaseline, createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";

function App() {
  let theme = createTheme({  
    typography: {
      fontFamily: 
        'Aftika',
    },
  });
  theme = responsiveFontSizes(theme);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
          
        <Router />
      </ThemeProvider>
      
    </div>
  );
}

export default App;
