import { Footer } from "../Components/Footer"
import { Header } from "../Components/Header"
import bgVideo from '../assets/bg.mp4';
import BackgroundVideo from 'react-background-video-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, FreeMode } from 'swiper/modules';
import 'swiper/css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { SplitText } from 'gsap/all';

import "./faq.css"
import { reviewlist } from "../confdata";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { TweenMax } from "gsap/all";

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother, SplitText, TweenMax);


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1
  }
};

export const Faq = () => {
  
  useEffect(() => {
    const faqCards = gsap.utils.toArray('.faq-card');
    const titleSpells = new SplitText('.faq-title', {type: 'chars'});
    gsap.from(titleSpells.chars, {
      duration: 0.8, 
      autoAlpha: 1,
      y: 300, 
      
      stagger: 0.05,
      onComplete: () => {
        faqCards.map((cardItem, key) => {
          gsap.fromTo(cardItem, { opacity: 0, clipPath: `inset(0px 0px 100%)`}, {
            clipPath: `inset(0px 0px 0%)`,
            opacity: 1,
            duration: 0.5,
            delay: 0.5 * key,
            onComplete: () => {
              const cardTitle = cardItem.querySelector('.faq-card-title');
              gsap.to(cardTitle, {opacity: 1});
              const cardDetail = cardItem.querySelector('.faq-card-detail');
              gsap.to(cardDetail, {opacity: 1});
              const cardHr = cardItem.querySelector('.faq-card-hr');
              gsap.to(cardHr, {opacity: 1});
              const cardTitleTxt = new SplitText(cardTitle, {type: 'chars'});
              const cardDetailTxt = new SplitText(cardDetail, {type: 'chars,words'});
              // TweenMax.set(cardDetailTxt, {"box-sizing":"border-box"});
              gsap.timeline()
                  .fromTo(cardTitleTxt.words, {autoAlpha:0}, {
                  duration: .5,
                  autoAlpha: 1,
                  delay: 0.5 * key,
                  stagger: {
                    each: .08,
                    onStart() {
                      let target = this.targets()[0];
                      let cursorPos = target.offsetLeft + target.offsetWidth;
                      gsap.set('.cursor', {x: cursorPos + 3})
                    }
                  }
                });
              gsap.timeline()
                  .fromTo(cardHr, {clipPath: `inset(0px 100% 0px 0px)`},{
                    clipPath: `inset(0px 0% 0px 0px)`,
                    duration: 0.5,
                    delay: (0.5 * key)+0.2
                  })
              gsap.timeline()
                .fromTo(cardDetailTxt.chars, {autoAlpha:0}, {
                duration: .1,
                autoAlpha: 1,
                delay: 0.5+(1 * key),
                stagger: {
                  each: .04,
                  onStart() {
                    let target = this.targets()[0];
                    let cursorPos = target.offsetLeft + target.offsetWidth;
                    gsap.set('.cursor', {x: cursorPos + 3})
                  },
                  onEnd() {
                    cardDetailTxt.revert();
                  }
                }
              });
            }
          })
        })
      }
    });

  }, [])

  return (
    <div className="faq-page h-screen bg-[#0e0e0e]">
      <Header />
      <section className="faq-section h-fit w-full bg-[#0e0e0e] pt-20 md:pt-36 lg:pt-36 px-2 md:px-28 lg:px-28">
        <div className="w-full mx-auto  py-5 ">
          <div className="w-full px-6 md:px-0 lg:px-0 py-0">
            <h1 className="faq-title relative z-10 overflow-hidden text-white text-center md:text-start lg:text-start font-bold text-[106pt] md:text-[153pt] lg:text-[153pt] leading-[175px] md:leading-[239px] lg:leading-[239px]"

            >FAQ</h1>
          </div>
          <div className="faq-main px-6 md:px-0 lg:px-0 -mt-8">
            <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-3 md:gap-24 lg:gap-24 px-2">
              <div className="relative opacity-0 faq-card rounded-b-lg rounded-tr-lg w-full h-full">
                {/* <BackgroundVideo 
                  className='absolute w-full h-full object-none object-left rounded-b-3xl rounded-tr-none md:rounded-tr-3xl lg:rounded-tr-3xl'
                  src={bgVideo}
                  loop={true}
                  autoPlay={true}
                /> */}
                <video className='absolute w-full h-full object-none object-left rounded-b-3xl rounded-tr-none md:rounded-tr-3xl lg:rounded-tr-3xl' autoPlay playsInline loop muted >
                  <source src={bgVideo} type="video/mp4"></source>
                </video>
                <div className="relative top-0 left-0 w-full h-full pt-7 pb-5 px-5">
                  <div className="w-full pb-1 ">
                    <h5 className="faq-card-title opacity-0 text-[14.4pt] font-bold leading-[35px] text-start">Quote</h5>
                  </div>
                  <hr className="faq-card-hr opacity-0 border-black"/>
                  <div className="w-full text-start text-[9.53pt] pt-2">
                    <p className="faq-card-detail font-light opacity-0">Prices vary based on project complexity and scope. Request a personalised quote by <Link to={'/reachout'}><b>Reaching Out</b></Link>, and we’ll tailor a quote that aligns with your needs and budget</p>
                  </div>
                </div>
              </div>

              <div className="relative opacity-0 faq-card rounded-lg w-full h-full">
                <video className='absolute w-full h-full object-none object-center rounded-3xl' autoPlay playsInline loop muted >
                  <source src={bgVideo} type="video/mp4"></source>
                </video>
                <div className="relative top-0 left-0 w-full h-full pt-7 pb-5 px-5">
                  <div className="w-full pb-1 ">
                    <h5 className="faq-card-title opacity-0 text-[14.4pt] font-bold leading-[35px] text-start">Process</h5>
                  </div>
                  <hr className="faq-card-hr opacity-0 border-black"/>
                  <div className="w-full text-start text-[9.53pt] pt-2">
                    <p className="faq-card-detail font-light opacity-0">Our process consists of consultation, artistic exploration, clear communication, creative input, and real-time feedback.</p>
                  </div>
                </div>
              </div>

              <div className="relative opacity-0 faq-card rounded-lg w-full h-full">
                <video className='absolute w-full h-full object-none object-right rounded-3xl' autoPlay playsInline loop muted >
                  <source src={bgVideo} type="video/mp4"></source>
                </video>
                <div className="relative top-0 left-0 w-full h-full pt-7 pb-5 px-5">
                  <div className="w-full pb-1 ">
                    <h5 className="faq-card-title opacity-0 text-[14.4pt] font-bold leading-[35px] text-start">Formats</h5>
                  </div>
                  <hr className="faq-card-hr opacity-0 border-black"/>
                  <div className="w-full text-start text-[9.53pt] pt-2">
                    <p className="faq-card-detail font-light opacity-0">Upon project completion, your files will be delivered in industry-standard formats (e.g., JPEG, PNG, PDF). Need a specific format? Just let us know, and we’ll generate it for you.</p>
                  </div>
                </div>
              </div>

              <div className="relative opacity-0 faq-card rounded-lg w-full h-full">
                <video className='absolute w-full h-full object-none object-left-top rounded-3xl' autoPlay playsInline loop muted >
                  <source src={bgVideo} type="video/mp4"></source>
                </video>
                <div className="relative top-0 left-0 w-full h-full pt-7 pb-5 px-5">
                  <div className="w-full pb-1 ">
                    <h5 className="faq-card-title opacity-0 text-[14.4pt] font-bold leading-[35px] text-start">Questions?</h5>
                  </div>
                  <hr className="faq-card-hr opacity-0 border-black"/>
                  <div className="w-full text-start text-[9.53pt] pt-2">
                    <p className="faq-card-detail font-light opacity-0">Simply fill out our <Link to={'/reachout'}><b>Reaching Out</b></Link> form, detailing your requirements. We’ll get back to you promptly, via email or phone, with a tailored Discovery Questionnaire and Proposal, along with any additional information you may need before we begin.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pb-12 pt-6 md:pt-6 lg:pt-6 px-8 md:px-28 lg:px-28 bg-[#0e0e0e]">
        <div className="w-full">
          {/* <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={{
              enabled: true,
              momentumBounce: false,
              momentum: false,
              sticky: false,
            }}
            pagination={{
              clickable: true,
            }}
            speed={5000}
            loop={true}
            loopAddBlankSlides={true}
            loopAdditionalSlides={3}
            grabCursor={true}
            observer={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              stopOnLastSlide: false,
            }}
            modules={[Autoplay, FreeMode]}
            breakpoints={{
              // when window width is <= 499px
              499: {
                  slidesPerView: 1,
                  spaceBetweenSlides: 50
              },
              // when window width is <= 999px
              999: {
                  slidesPerView: 5,
                  spaceBetweenSlides: 50
              },
              // when window width is <= 2600px
              2600: {
                slidesPerView: 5,
                spaceBetweenSlides: 50
              }
            }
              
          }
          >
            {reviewlist.map((reviewItem, key) => (
              <SwiperSlide key={key}>
                <div className="review-item text-left text-white text-[13px]">
                  <div className="star_5 text-[30px]"></div>
                  <div className="review-detail mt-2 px-1">
                    {reviewItem.detail}
                  </div>
                  <div className="review-user px-1 mt-2 font-semibold">
                    {reviewItem.name}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
          <Carousel responsive={responsive}
          additionalTransfrom={0}
          arrows={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1}
          centerMode={false}
          className=""
          customTransition="all 5s linear"
          transitionDuration={5000}
        >
            {reviewlist.map((reviewItem, key) => (
              <div key={key} className="review-item h-full flex flex-col justify-between text-left text-white text-[13px]">
                <div className="w-full">
                  <div className="star_5 text-[30px]"></div>
                  <div className="review-detail mt-2 px-1">
                    {reviewItem.detail}
                  </div>
                </div>
                
                <div className="review-user px-1 mt-2 font-semibold">
                  {reviewItem.name}
                </div>
              </div>
            ))}
        </Carousel>
        </div>
      </section>
      <Footer />
    </div>
  )
}