import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import HomesteadProd1 from "../../assets/products/3D/3D-Homestead2100-1.png";
import HomesteadProd2 from "../../assets/products/3D/3D-Homestead2100-2.png";
import HomesteadProd3 from "../../assets/products/3D/3D-Homestead2100-3.png";

import MoochProd1 from "../../assets/products/3D/3D-Mooch-1.png"
import MoochProd2 from "../../assets/products/3D/3D-Mooch-2.png"
import MoochProd3 from "../../assets/products/3D/3D-Mooch-3.png"

import VeraProd1 from "../../assets/products/3D/Vera-1.png"
import VeraProd2 from "../../assets/products/3D/Vera-2.png"
import VeraProd3 from "../../assets/products/3D/Vera-3.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const packageProsData = [
  {
    tagName: 'Homestead 2100',
    bannerImg: HomesteadProd1,
    mobileBanner: HomesteadProd1,
    leftImg: HomesteadProd2,
    rightImg: HomesteadProd3,
    brief: `Commissioned to create a 3D render and video for a self-contained vision farm utilising Tesla batteries and cutting-edge robots. Their objective: cultivate diverse produce and breed fish sustainably within a shipping container.`,
    process: 'Translating client blueprints into precise 3D models alongside a compelling pitch deck and presentation to showcase the innovative vision farm concept, aiming to secure investor interest. The final deliverables successfully communicated the project’s potential and garnered valuable investment for the client.',
    process1: '',
    review: `Outstanding professional Freelancer. A true expert designer. A+++`,
    reviewUser: `Jakub S.`,
    reserved: false,
  },
  {
    tagName: 'Mooch Sofas',
    bannerImg: MoochProd1,
    mobileBanner: MoochProd1,
    leftImg: MoochProd2,
    rightImg: MoochProd3,
    brief: `Tasked with creating precise and detailed 3D renders for a client specialising in the construction and sale of sofas, chairs, and furniture in Manchester, UK.`,
    process: 'We analysed the client’s specifications. Utilised advanced rendering techniques to bring furniture designs to life in 3D. Iterative feedback loops with the client ensured the final renders met their expectations, resulting in visually stunning representations of their products.',
    process1: '',
    review: `The team’s 3D renders for our Manchester-based furniture project were executed with exemplary precision and detail. Their work significantly contributed to our marketing success by showcasing the furniture’s design in a realistic and appealing manner. We’re impressed with the outcome and highly recommend their services.`,
    reviewUser: `Charlie.`,
    reserved: true,
  },
  {
    tagName: 'Vera Houses',
    bannerImg: VeraProd1,
    mobileBanner: VeraProd1,
    leftImg: VeraProd2,
    rightImg: VeraProd3,
    brief: `Produce precise and visually appealing renders for easy-to-build houses. Ensure accuracy and aesthetic quality in showcasing architectural designs and interiors.`,
    process: `We meticulously crafted renders to showcase the ease of construction. Iterative refinement sessions and attention to detail resulted in accurate and aesthetically pleasing final renders.`,
    process1: '',
    review: `Incredible work! The team flawlessly brought our architectural visions to life with precise, visually stunning renders. Accuracy and aesthetic quality were top priorities, and they nailed it. The renders not only showcased designs but also elevated our presentations, making a lasting impact on our clients. Highly recommended!`,
    reviewUser: `Simon. L`,
    reserved: false,
  },
]


export const Threed = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])


    return (
        <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
          <Header />
          {packageProsData.map((packageData, key) => (
            <ServiceSection 
            key={key}
            pageIndex={key}
            isScrollAnimation={key !== 0} reserved={packageData.reserved}
            mobileBanner={packageData.mobileBanner}
            bannerImg={packageData.bannerImg} prod1={packageData.leftImg} prod2={packageData.rightImg}
            tagName={packageData.tagName} brief={packageData.brief} process={packageData.process} 
            process1={packageData.process1} review={packageData.review} reviewuser={packageData.reviewUser}
            />
          ))}
          <Footer />
        </div>
      )
}