import { useState } from "react"
import './reachicons.css'
export const FacebookIcon = () => {
    
    return (
        <svg className="social-btn" width="100%" height="100%" viewBox="0 0 38 38"  xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="17" className="bg-circle"/>
            <path fill-rule="evenodd" className="forward-path" clip-rule="evenodd" d="M31.7171 5.49602C38.9661 12.745 38.9661 24.4979 31.7171 31.7469C24.4681 38.9958 12.7152 38.9958 5.46623 31.7469C-1.78274 24.4979 -1.78274 12.745 5.46623 5.49601C12.7152 -1.75296 24.4681 -1.75296 31.7171 5.49602ZM20.1817 32.5073L15.3694 32.5073L15.3694 21.0539L11.3229 21.0539L11.3228 16.4273L15.3694 16.4273L15.3694 12.9856C15.3694 9.54391 17.3378 6.66641 20.8925 6.89224L24.6112 7.06141L24.6112 10.8981L22.533 10.8981C21.3852 10.8981 20.1817 11.6314 20.1817 12.9289L20.1817 16.4273L24.4472 16.4273L23.7364 21.0539L20.1817 21.0539L20.1817 32.5073Z" />
        </svg>

    )
}

export const LinkedInIcon = () => {
    return (
        <svg className="social-btn" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="17" className="bg-circle"/>
            <path fill-rule="evenodd" className="forward-path" clip-rule="evenodd" d="M31.9353 5.49601C39.1843 12.745 39.1843 24.4979 31.9353 31.7469C24.6864 38.9958 12.9335 38.9958 5.68449 31.7469C-1.56448 24.4979 -1.56448 12.745 5.68449 5.49602C12.9335 -1.75295 24.6864 -1.75296 31.9353 5.49601ZM12.5989 14.8424L8.45261 14.8424L8.45261 28.6969L12.5989 28.6969L12.5989 14.8424ZM13.0083 10.309C13.0083 8.93366 11.8824 7.81334 10.5003 7.81334C9.11826 7.81334 8.04323 8.93366 8.04323 10.309C8.04323 11.6843 9.11826 12.8047 10.5003 12.8047C11.8824 12.8047 13.0083 11.6843 13.0083 10.309ZM19.1507 14.8424L15.056 14.8424L15.056 28.6969L19.1507 28.6969L19.1507 21.2091C19.2023 19.6301 20.5328 18.4079 22.1195 18.4079C23.7063 18.4079 24.7805 19.6301 24.8321 21.2091L24.8321 27.4234L24.8321 28.6969L28.9268 28.6969L28.9268 19.3758C28.9268 16.8297 26.8283 14.6901 24.218 14.6901L23.0922 14.6901C21.4539 14.6901 20.021 15.5048 19.1507 16.7783L19.1507 14.8424Z" />
        </svg>
    )
}

export const InstagramIcon = () => {
    return (
        <svg className="social-btn" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="17" className="bg-circle"/>
            
            <path fill-rule="evenodd" className="forward-path" clip-rule="evenodd" d="M32.1541 5.49602C39.4031 12.745 39.4031 24.4979 32.1541 31.7469C24.9051 38.9958 13.1522 38.9958 5.90324 31.7469C-1.34572 24.4979 -1.34573 12.745 5.90324 5.49601C13.1522 -1.75296 24.9051 -1.75296 32.1541 5.49602ZM24.2677 6.87784L13.7746 6.87784C10.4038 6.87784 7.63114 9.77295 7.63114 13.2926L7.63114 24.1922C7.63114 27.7119 10.4038 30.607 13.7746 30.607L24.2677 30.607C27.6385 30.607 30.4111 27.7119 30.4111 24.1922L30.4111 13.2926C30.4111 9.77295 27.6385 6.87784 24.2677 6.87784ZM26.4422 12.3848C26.4422 11.6467 25.844 11.0221 25.1372 11.0221C24.3756 11.0221 23.8322 11.6467 23.8322 12.3848C23.8322 13.1228 24.3764 13.7474 25.1372 13.7474C25.844 13.7474 26.4422 13.1228 26.4422 12.3848ZM19.0485 12.2704L18.9938 12.2704C15.623 12.2704 12.8504 15.2227 12.8504 18.7424C12.8504 22.3193 15.623 25.2144 18.9938 25.2144L19.0485 25.2144C22.4193 25.2144 25.2467 22.3184 25.2467 18.7424C25.2467 15.2227 22.4193 12.2704 19.0485 12.2704ZM19.0485 14.4846L18.9938 14.4846C16.7646 14.4846 14.9161 16.4147 14.9161 18.7424C14.9161 21.1264 16.7646 23.0565 18.9938 23.0565L19.0485 23.0565C21.2777 23.0565 23.1261 21.1264 23.1261 18.7424C23.1261 16.4147 21.2777 14.4847 19.0485 14.4846ZM24.2122 8.9214L13.8285 8.9214C11.4914 8.9214 9.58825 10.9078 9.58825 13.349L9.58825 24.135C9.58825 26.5754 11.4906 28.5626 13.8285 28.5626L24.2122 28.5626C26.5493 28.5626 28.4524 26.5762 28.4524 24.135L28.4524 13.349C28.4524 10.9086 26.5501 8.9214 24.2122 8.9214Z" />
        </svg>

    )
}